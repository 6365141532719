import { NavLink } from "react-router-dom";
import { programmingSkillsDetails } from "../Resume/Resume";
import React from "react";

const Skills = () => {
    return (
        <div className="projectdetails">
            <div className="container">
                <div className="row py-3">
                    <div className="mb-4">
                        <NavLink to="/" className="backtohome mt-2">Back To Home</NavLink>
                    </div>
                    <h2 style={{ borderBottom: '2px solid', width: "100%" }}>Skills</h2>

                    <div className="resume-screen-container programming-skills-container" style={{padding: "0 30px"}}>

                        {
                            programmingSkillsDetails.map((skill, index) => <div className="skill-parent" key={index}>
                                <div className="heading-bullet"></div>
                                <span>{skill.skill}</span>
                                <div className="skill-percentage">
                                    <div
                                        className="active-percentage-bar"
                                        style={{ width: skill.ratingPercentage + "%" }}
                                    ></div>
                                </div>
                            </div>)
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Skills;