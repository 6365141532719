import React from "react";
import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import "./Resume.css";
import { NavLink } from "react-router-dom";

export const programmingSkillsDetails = [
  { skill: "JavaScript", ratingPercentage: 85 },
  { skill: "React JS", ratingPercentage: 70 },
  { skill: "HTML", ratingPercentage: 85 },
  { skill: "CSS", ratingPercentage: 85 },
  { skill: "Node JS", ratingPercentage: 70 },
  { skill: "Express JS", ratingPercentage: 65 },
  { skill: "Mongo DB", ratingPercentage: 65 },
  { skill: "Python", ratingPercentage: 85 },
  { skill: "Flask", ratingPercentage: 60 },
  { skill: "Django", ratingPercentage: 70 },
  { skill: "SQL", ratingPercentage: 70 },
];

export default function Resume(props) {
  const [selectedBulletIndex, setSelectedBulletIndex] = React.useState(0);
  const [carousalOffsetStyle, setCarousalOffsetStyle] = React.useState({});

  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;
    Animations.animations.fadeInScreen(props.id);
  };
  const fadeInSubscription =
    ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  const ResumeHeading = (props) => {
    return (
      <div className="resume-heading">
        <div className="resume-main-heading">
          <div className="heading-bullet"></div>
          <span style={{ fontSize: "20px" }}>
            {props.heading ? props.heading : ""}
          </span>
          {props.fromDate && props.toDate ? (
            <div className="heading-date">
              {props.fromDate + "-" + props.toDate}
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div className="resume-sub-heading">
          <span>{props.subHeading ? props.subHeading : ""}</span>
        </div>
        <div className="resume-heading-description">
          <span>{props.description ? props.description : ""}</span>
        </div>
      </div>
    );
  };

  const ProjectHeading = (props) => {
    return (
      <div className="resume-heading">
        <div className="resume-main-heading">
          <div className="heading-bullet"></div>
          <span style={{ fontSize: "20px" }}>
            {props.heading ? props.heading : ""}
          </span>
          {props.details ? (
            <div className="heading-details">
              {/* <a target="_blank" style={{color:'white'}} href={props.details ? props.details:""}>Details...</a> */}
              {/* <a target="_blank" style={{ color: 'white' }} href={props.details ? props.details : ""}></a> */}
              <NavLink
                to={props.details ? props.details : ""}
                style={{ color: "white" }}
                target="_blank"
              >
                Details...
              </NavLink>
            </div>
          ) : (
            <div></div>
          )}
        </div>
        {/* <div className="resume-sub-heading">
          <span>{props.subHeading ? props.subHeading : ""}</span>
        </div>
        <div className="resume-heading-description">
          <span>{props.description ? props.description : ""}</span>
        </div> */}
      </div>
    );
  };

  const resumeBullets = [
    { label: "Education", logoSrc: "education.svg" },
    { label: "Certificate", logoSrc: "certificate.svg" },
    { label: "Work History", logoSrc: "work-history.svg" },
    { label: "Programming & Tool Skills", logoSrc: "programming-skills.svg" },
    { label: "Projects", logoSrc: "projects.svg" },
    { label: "Interests", logoSrc: "interests.svg" },
  ];

  // const programmingSkillsDetails = [
  //   { skill: "JavaScript", ratingPercentage: 85 },
  //   { skill: "React JS", ratingPercentage: 85 },
  //   { skill: "HTML", ratingPercentage: 85 },
  //   { skill: "CSS", ratingPercentage: 85 },
  //   { skill: "Node JS", ratingPercentage: 85 },
  //   { skill: "Express JS", ratingPercentage: 85 },
  //   { skill: "Mongo DB", ratingPercentage: 85 },
  //   { skill: "Python", ratingPercentage: 85 },
  //   { skill: "Flask", ratingPercentage: 85 },
  //   { skill: "Django", ratingPercentage: 85 },
  //   { skill: "SQL", ratingPercentage: 85 }
  // ];

  const projectsDetails = [
    {
      title: "Web Development Projects",
      duration: { fromDate: "2021", toDate: "Present" },
      description:
        "A Personal Portfolio website to showcase all my details and projects at one place",
      subHeading: "Technologies Used: React JS, HTML, CSS",
      details: "/project/web-development",
    },
    {
      title: "Python Projects",
      duration: { fromDate: "2021", toDate: "Present" },
      description:
        "A Mobile E-shop website to showcase all my details and projects at one place",
      subHeading: "Technologies Used: React JS, HTML, CSS",
      details: "/project/python-projects",
    },
    {
      title: "DevOps Projects",
      duration: { fromDate: "2021", toDate: "Present" },
      description:
        "A E-commerce website to showcase all my details and projects at one place",
      subHeading: "Technologies Used: React JS, HTML, CSS",
      details: "/project/devops",
    },
    {
      title: "Professional Projects",
      duration: { fromDate: "2021", toDate: "Present" },
      description:
        "A E-commerce website to showcase all my details and projects at one place",
      subHeading: "Technologies Used: React JS, HTML, CSS",
      details: "/project/professional-projects",
    },
  ];

  const certificateDetails = [
    {
      title: "Professional Certificate",
      duration: { fromDate: "2021", toDate: "Present" },
      description: "",
      subHeading: "",
      details: "/certificate/professional-certificate",
    },
    {
      title: "Vendor Certificate",
      description: "",
      subHeading: "",
      details: "/Certificate/vendor-certificate",
    },
  ];

  const resumeDetails = [
    <div className="resume-screen-container" key="education">
      <ResumeHeading
        heading={"Masters in Computer Science & Engineering"}
        subHeading={"Jagannath University"}
        // fromDate={"2022"}
        // toDate={"2023"}
      />
      <ResumeHeading
        heading={"Bachelor in Computer Science & Engineering"}
        subHeading={"University of Information Technology & Sciences"}
        // fromDate={"2022"}
        // toDate={"2023"}
      />
      <ResumeHeading
        heading={"Diploma in Telecommunication Engineering"}
        subHeading={"Daffodil Polytechnic Institute"}
        // fromDate={"2022"}
        // toDate={"2023"}
      />
      <ResumeHeading
        heading={"Secondary School Certificate"}
        subHeading={"Dakhin Banasree Model School & College"}
        // fromDate={"2022"}
        // toDate={"2023"}
      />
    </div>,
    <div className="resume-screen-container" key="cet">
      {certificateDetails.map((projectDetails, index) => (
        <ProjectHeading
          key={index}
          heading={projectDetails.title}
          subHeading={projectDetails.subHeading}
          description={projectDetails.description}
          details={projectDetails.details}
        />
      ))}
    </div>,
    <div className="resume-screen-container" key="work-experience">
      <div className="experience-container">
        <ResumeHeading
          heading={"Nec Money Transfer Limited"}
          subHeading={"AGM & Head of IT"}
          fromDate={"2018"}
          toDate={"Present"}
        />
        <div className="experience-description">
          {/* <span className="resume-description-text">
            I am currently working as an IT Manager in Nec Money Transfer
            Limited.
          </span> */}
        </div>
        {/* <div className="experience-description">
          <span className="resume-description-text">
            I have worked on the following projects:
          </span>
        </div> */}
        <ResumeHeading
          heading={"National Exchange Company S.R.L"}
          subHeading={"Senior IT Officer"}
          fromDate={"2013"}
          toDate={"2017"}
        />
        <div className="experience-description">
          {/* <span className="resume-description-text">
            I was working as an IT Manager in National Exchange Company S.R.L
          </span> */}
        </div>
        {/* <div className="experience-description">
          <span className="resume-description-text">
            I have worked on the following projects:
          </span>
        </div> */}
      </div>
    </div>,
    <div
      className="resume-screen-container programming-skills-container"
      key="programming-skills"
    >
      {programmingSkillsDetails.map((skill, index) => (
        <div className="skill-parent" key={index}>
          {index <= 9 && (
            <>
              <div className="heading-bullet"></div>
              <span>{skill.skill}</span>
              <div className="skill-percentage">
                <div
                  className="active-percentage-bar"
                  style={{ width: skill.ratingPercentage + "%" }}
                ></div>
              </div>
            </>
          )}
          {index === 10 && (
            <div>
              <NavLink to="/skills" target="_blank" className="heading-details">
                More...
              </NavLink>
            </div>
          )}
        </div>
      ))}
    </div>,
    <div className="resume-screen-container" key="projects">
      {projectsDetails.map((projectDetails, index) => (
        <ProjectHeading
          key={index}
          heading={projectDetails.title}
          subHeading={projectDetails.subHeading}
          description={projectDetails.description}
          details={projectDetails.details}
        />
      ))}
    </div>,
    <div className="resume-screen-container" key="interests">
      <ResumeHeading heading="Music" subHeading="I love to play music" />
      <ResumeHeading heading="Traveling" subHeading="I love to travel" />
      <ResumeHeading heading="Reading" subHeading="I love to read" />
    </div>,
  ];

  const handleCarousal = (index) => {
    let offsetHeight = 360;

    let newCarousalOffset = {
      style: { transform: "translateY(" + index * offsetHeight * -1 + "px)" },
    };

    setCarousalOffsetStyle(newCarousalOffset);
    setSelectedBulletIndex(index);
  };

  const getBullets = () => {
    return resumeBullets.map((bullet, index) => (
      <div
        onClick={() => handleCarousal(index)}
        className={
          index === selectedBulletIndex ? "bullet selected-bullet" : "bullet"
        }
        key={index}
      >
        <img
          className="bullet-logo"
          src={require(`../../assets/Resume/${bullet.logoSrc}`)}
          alt="oops,,, no internet connection"
        />
        <div className="bullet-label">{bullet.label}</div>
      </div>
    ));
  };

  const getResumeScreens = () => {
    return (
      <div
        style={carousalOffsetStyle.style}
        className="resume-details-carousal"
      >
        {resumeDetails.map((ResumeDetail) => ResumeDetail)}
      </div>
    );
  };

  return (
    <div className="resume-container screen-container" id={props.id || ""}>
      <div className="resume-parent">
        <ScreenHeading title={"Resume"} subHeading={"My Formal Bio Details"} />
        <div className="resume-card">
          <div className="resume-bullets">
            <div className="bullet-container">
              <div className="bullet-icons"></div>
              <div className="bullets">{getBullets()}</div>
            </div>
          </div>
          <div className="resume-bullet-details">{getResumeScreens()}</div>"
        </div>
      </div>
    </div>
  );
}

// {programmingSkillsDetails.map((skill, index) => (
//   <div className="skill-parent" key={index}>
//     <div className="heading-bullet"></div>
//     <span>{skill.skill}</span>
//     <div className="skill-percentage">
//       <div
//         className="active-percentage-bar"
//         style={{ width: skill.ratingPercentage + "%" }}
//       ></div>
//     </div>
//   </div>
// ))}
// <div>
//   <button className="heading-details">More...</button>
// </div>
