import React from "react";
import "./Project.css"
import { NavLink } from "react-router-dom";
import Project from "./Project";
import { webProjects } from "./projectData";

export default function WebDevelopment() {
    return (
        <div className="projectdetails">
            <div className="container">
                <div className="row py-3">
                    <div className="mb-4">
                        <NavLink to="/" className="backtohome mt-2">Back To Home</NavLink>
                    </div>

                    <h2 style={{ borderBottom: '2px solid', width: "100%" }}>Web Development Project</h2>
                    
                    {/* Projects */}
                    {
                        webProjects.map(project => <Project title={project.title} github={project.github} description={project.description}></Project>)
                    }
                </div>
            </div>
        </div>
    );
}
