import React from "react";
import "./Project.css"
import { NavLink } from "react-router-dom";
import Project from "./Project";
import { devOpsProjects } from "./projectData";

export default function DevOps() {

    return (
        <div className="projectdetails">
            <div className="container">
                <div className="row py-3">
                    <div className="mb-4">
                        <NavLink to="/" className="backtohome mt-2">Back To Home</NavLink>
                    </div>

                    <h2 style={{ borderBottom: '2px solid', width: "100%" }}>DevOps Projects</h2>

                    {
                        devOpsProjects.map(project => <Project title={project.title} github={project.github} description={project.description}></Project>)
                    }
                </div>
            </div>
        </div>
    );
}




{/* project start */ }
{/* <div className="col-md-12">
<div class="project-info">
    <h2>Mobile App Development for Ecommerce</h2>
    <p><a href="https://github.com/Meheady" target="_blank">GitHub: https://github.com/Meheady</a></p>
</div>
<div class="work-list">
    <h2>Show Work List</h2>
    <ul>
        <li>Task 1</li>
        <li>Task 2</li>
        <li>Task 3</li>
    </ul>
</div>
</div> */}
{/* end project */ }