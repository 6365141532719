import "./App.css";
import PortfolioContainer from "./PortfolioContainer/PortfolioContainer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import DevOps from "./PortfolioContainer/Projects/DevOPs";
import WebDevelopment from "./PortfolioContainer/Projects/WebDevelopment";
import PythonProjects from "./PortfolioContainer/Projects/PythonProject";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import VendorCertificate from "./PortfolioContainer/Certificates/VendorCertificate";
import ProfessionalCertificate from "./PortfolioContainer/Certificates/ProfessionalCertificate";
import Skills from "./PortfolioContainer/Skills/Skills";
import ProfessionalProjects from "./PortfolioContainer/Projects/ProfessionalProjects";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" index element={<PortfolioContainer />} />
          <Route path="/skills" element={<Skills />} />
          <Route path="/project/devops" element={<DevOps />} />
          <Route path="/project/web-development" element={<WebDevelopment />} />
          <Route path="/project/python-projects" element={<PythonProjects />} />
          <Route
            path="/project/professional-projects"
            element={<ProfessionalProjects />}
          />
          <Route
            path="/certificate/professional-certificate"
            element={<ProfessionalCertificate />}
          />
          <Route
            path="/certificate/vendor-certificate"
            element={<VendorCertificate />}
          />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
}

export default App;
