export const webProjects = [
    {
        title: "Project Title",
        github: "https://github.com/Meheady",
        description: "Project description"
    },
    {
        title: "Project Title",
        github: "https://github.com/Meheady",
        description: "Project description"
    },
];

export const pythonProjects = [
    {
        title: "Project Title",
        github: "https://github.com/Meheady",
        description: "Project description"
    },
    {
        title: "Project Title",
        github: "https://github.com/Meheady",
        description: "Project description"
    },
]

export const devOpsProjects = [
    {
        title: "Project Title",
        github: "https://github.com/Meheady",
        description: "Project description"
    },
    {
        title: "Project Title",
        github: "https://github.com/Meheady",
        description: "Project description"
    },
];