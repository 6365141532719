import React, { useRef, useState } from "react";
import Typical from "react-typical";
import axios from "axios";
import { toast } from "react-toastify";

import imgBack from "../../../src/images/mailz.jpeg";
import load1 from "../../../src/images/load2.gif";
import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import "./ContactMe.css";
import emailjs from "@emailjs/browser";
import { FaSquareXTwitter, FaLinkedin } from "react-icons/fa6";
import { SiBlogger } from "react-icons/si";

export default function ContactMe(props) {
  const form = useRef(null);

  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;
    Animations.animations.fadeInScreen(props.id);
  };
  // const fadeInSubscription =
  ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [banner, setBanner] = useState("");
  const [bool, setBool] = useState(false);

  const handleName = (e) => {
    setName(e.target.value);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleMessage = (e) => {
    setMessage(e.target.value);
  };

  const submitForm = async (e) => {
    e.preventDefault();

    if (name.length === 0 || email.length === 0 || message.length === 0) {
      return toast.error("Please, fill-up all fields");
    }
    emailjs
      .sendForm(
        `${process.env.REACT_APP_SERVICE_KEY}`,
        `${process.env.REACT_APP_TEMPLATE_KEY}`,
        form.current,
        {
          publicKey: `${process.env.REACT_APP_PUBLIC_KEY}`,
        }
      )
      .then(
        () => {
          toast.success("Successfully sent. I will contact you shortly.");
          // form.current.reset();
          // Reset form fields
          setName("");
          setEmail("");
          setMessage("");
        },
        (error) => {
          toast.error("FAILED...", error.text);
        }
      );
  };

  return (
    <>
      <div className="main-container" id={props.id || ""}>
        <ScreenHeading title={"Contact Me"} subHeading={"Lets Keep In Touch"} />
        <div className="central-form">
          <div className="col">
            <h2 className="title">
              <Typical loop={Infinity} steps={["Get In Touch 📧", 1000]} />
            </h2>
            <div className="colz">
              <div
                className="colz-icon"
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <a href="https://www.facebook.com/Nizammnb">
                  <i className="fa fa-facebook-square"></i>
                </a>
                {/* <a href="#">
                <i className="fa fa-google-plus-square"></i>
              </a> */}
                {/* <a href="https://www.instagram.com/nizam.mnb/">
                <i className="fa fa-instagram"></i>
              </a> */}
                <a href="https://www.youtube.com/channel/UCaiRYB5YbfluJT97rYW1mEA">
                  <i className="fa fa-youtube-square"></i>
                </a>
                <a href="https://twitter.com/mnb00754">
                  {/* <i className="fa fa-twitter"></i> */}
                  {/* <i className="fa-brands fa-x-twitter"></i> */}
                  <FaSquareXTwitter
                    style={{ color: "white", fontSize: "24px" }}
                  />
                </a>
                <a href="https://twitter.com/mnb00754">
                  <FaLinkedin style={{ color: "white", fontSize: "24px" }} />
                </a>
                <a href="https://twitter.com/mnb00754">
                  <SiBlogger style={{ color: "white", fontSize: "24px" }} />
                </a>
              </div>
            </div>
          </div>
          <div className="back-form">
            <div className="img-back">
              <h4>Send Your Email Here!</h4>
              <img src={imgBack} alt="Not found" />
            </div>
            <form ref={form} onSubmit={submitForm}>
              <p>{banner}</p>
              <label htmlFor="name">Name</label>
              <input
                type="text"
                onChange={handleName}
                value={name}
                name="user_name"
                required
              />

              <label htmlFor="email">Email</label>
              <input
                type="email"
                onChange={handleEmail}
                value={email}
                name="user_email"
                required
              />

              <label htmlFor="message">Message</label>
              <textarea
                type="text"
                onChange={handleMessage}
                value={message}
                name="message"
                required
              />

              <div className="send-btn">
                <button type="submit" value="Send">
                  Send
                  <i className="fa fa-paper-plane" />
                  {bool ? (
                    <b className="load">
                      <img src={load1} alt="Not responding" />
                    </b>
                  ) : (
                    ""
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Footer */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "100px",
          background: "#24263B",
          color: "white",
          padding: "30px 20px",
        }}
      >
        <p style={{ margin: "0" }}>
          Copyright &copy; {new Date().getFullYear()} | All Rights Reserved
        </p>
      </div>
    </>
  );
}

/* 
try {
  let data = {
    name,
    email,
    message,
  };
  setBool(true);
  // const res = await axios.post(`https://my-backend-brown.vercel.app/contact`, data);

  const res = await axios({
    headers: {
      'Access-Control-Allow-Origin': '*',
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    // url: 'https://my-backend-c90a3twpf-kh-shakils-projects.vercel.app/contact',
    url: 'http://localhost:5000/contact',
    data: {...data},
    method: 'POST'
  });

  console.log(res)

  if (name.length === 0 || email.length === 0 || message.length === 0) {
    setBanner(res.data.msg);
    toast.error(res.data.msg);
    setBool(false);
  } else if (res.status === 200) {
    setBanner(res.data.msg);
    toast.success(res.data.msg);
    setBool(false);

    setName("");
    setEmail("");
    setMessage("");
  }
} catch (error) {
  console.log(error);
} */
