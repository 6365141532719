import React from "react";
import "./Certificate.css";
import { NavLink } from "react-router-dom";

export default function ProfessionalCertificate() {
  return (
    <div className="certificatedetails">
      <div className="container">
        <div className="row py-3">
          <div className="mb-4">
            <NavLink to="/" className="backtohome mt-2">
              Back To Home
            </NavLink>
          </div>

          {/* <h2 style={{borderBottom:'2px solid',width:"100%"}}></h2>     */}

          {/* certificate start */}

          <div className="col-md-12">
            <div class="project-info">
              <h2 style={{ textAlign: "center" }}>
                Professional Certificate's
              </h2>
            </div>

            <div class="work-list">
              <h2>List Of Professional Certificate:</h2>
              <ul>
                <li>Financial Compliance</li>
                <ul>
                  <li>
                    Certification on “Anti-Money Laundering & Terrorist
                    Financing Approaches”, organized by ABM GLOBAL COMPLIANCE UK
                    LIMITED (Specialist Financial Services Compliance Farm, UK).{" "}
                    <br />
                    <a
                      href="https://drive.google.com/file/d/1r6jtD8z87JFubg2wjzlOCrQU6_Hgy-ze/view"
                      target="blank"
                    >
                      Certificate Link
                    </a>
                  </li>
                  <li>
                    Certification on “Anti-Money Laundering Concepts AML, KYC
                    and Compliance”, organized by Udemy.com.
                  </li>
                </ul>
                <li>DevOps</li>
                <ul>
                  <li>
                    Certification on “DevOps Beginners to Advanced | Decoding
                    DevOps with Projects”, organized by Udemy.com.
                  </li>
                  <li>
                    Certification on “DevOps Coding Boot Camp”, organized by
                    Master Academy.
                  </li>
                </ul>
                <li>Programming</li>
                <ul>
                  <li>
                    Certification on “GoLang Coding Boot Camp”, organized by
                    Master Academy.
                  </li>
                  <li>
                    Certification on “Learning Django”, organized by Lynda.com.
                  </li>
                  <li>
                    Certification on “React.js Essential Training”, organized by
                    Lynda.com.
                  </li>
                </ul>
                <li>Windows Server</li>
                <ul>
                  <li>
                    Certification on “Windows Server 2019 Essential Training”,
                    organized by Lynda.com in 2019.
                  </li>
                  <li>
                    Certification on “Securing Windows Server 2019”, organized
                    by Lynda.com in 2019.
                  </li>
                </ul>
                <li>Software Architecture</li>
                <ul>
                  <li>
                    Certification on “Software Architecture Foundations”,
                    organized by Lynda.com.
                  </li>
                  <li>
                    Certification on “Software Architecture: Domain-Driven
                    Design”, organized by Lynda.com.
                  </li>
                </ul>
                <li>Cloud Computing</li>
                <ul>
                  <li>
                    Earn Batch from Qwiklabs.com: Cloud Engineering, Windows on
                    Google Cloud, Cloud Development, Data Engineering, Google
                    Cloud Essential, Exploring APIs, Baseline-Data, ML, AI
                  </li>
                  <li>
                    Certification on “AWS Cloud Quest: Cloud Practitioner”,
                    organized by Amazon Web Services Training and Certification.
                    <br />
                    <a
                      href="https://www.credly.com/badges/0d3d0236-b79b-4791-82ce-a5513139d723/public_url"
                      target="blank"
                    >
                      Badge Link
                    </a>
                  </li>
                </ul>
                <li>Other</li>
                <ul>
                  <li>
                    Certification on “Critical Thinking for Better Judgment and
                    Decision-Making”, by Lynda.com.
                  </li>
                  <li>
                    Certification on “Technical Support Fundamentals (Google IT
                    Support)”, organized by Coursera.com.
                  </li>
                  <li>
                    Certification on “Foundations of User Experience (UX)
                    Design”, organized by Coursera.com.
                  </li>
                </ul>
              </ul>
            </div>
          </div>

          {/* end certificate */}
        </div>
      </div>
    </div>
  );
}
