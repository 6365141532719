import React from "react";
import Typical from "react-typical";
// import ScrollService from "../../../utilities/ScrollService";
import "./Profile.css";
import { FaLinkedin, FaSquareXTwitter } from "react-icons/fa6";
import { SiBlogger } from "react-icons/si";

export default function Profile() {
  return (
    <div className="profile-container">
      <div className="profile-parent">
        <div className="profile-details">
          <div className="colz">
            <div className="colz-icon" style={{display: "flex", justifyContent: "center", alignItems: 'center', gap: "8px"}}>
              <a href="https://www.facebook.com/Nizammnb">
                <i className="fa fa-facebook-square"></i>
              </a>
              {/* <a href="#">
                <i className="fa fa-google-plus-square"></i>
              </a> */}
              {/* <a href="https://www.instagram.com/nizam.mnb/">
                <i className="fa fa-instagram"></i>
              </a> */}
              <a href="https://www.youtube.com/channel/UCaiRYB5YbfluJT97rYW1mEA">
                <i className="fa fa-youtube-square"></i>
              </a>
              <a href="https://twitter.com/mnb00754">
                {/* <i className="fa fa-twitter"></i> */}
                <FaSquareXTwitter style={{color: "white", fontSize: "24px"}}/>
              </a>
              <a href="https://twitter.com/mnb00754">
                <FaLinkedin style={{color: "white", fontSize: "24px"}}/>
              </a>
              <a href="https://twitter.com/mnb00754">
                <SiBlogger style={{color: "white", fontSize: "24px"}}/>
              </a>
            </div>
          </div>
          <div className="profile-details-name">
            <span className="primary-text">
              {" "}
              Hello, I'M <span className="highlighted-text">Nizam</span>
            </span>
          </div>
          <div className="profile-details-role">
            <span className="primary-text">
              {" "}
              <h1>
                {""}
                <Typical
                  loop={Infinity}
                  steps={[
                    "DevOps Engineer 🛠",
                    1000,
                    "Software Engineer 💻",
                    1000,
                    "MERN Stack Developer 🧬",
                    1000,
                    "Python Developer 🥋",
                    1000,
                  ]}
                />
              </h1>
              <span className="profile-role-tagline">
                Knack of building applications with front and back end
                operations.
              </span>
            </span>
          </div>
          <div className="profile-options">
            <button
              className="btn primary-btn"
              onClick={() => {

                let contactMeScreen = document.getElementById("ContactMe");
                if (!contactMeScreen) return;
                contactMeScreen.scrollIntoView({ behavior: "smooth" });

              }}
            >
              Contact Me
            </button>

            {/* <a className="btn highlighted-btn"
              href={ResumePdf}
              download="resume_Mostafa_Nizam.pdf"
            >Get Resume</a> */}
            {/* <NavLink className="btn highlighted-btn"
              href="/"
            >Buy Me a Coffee</NavLink> */}
          </div>
        </div>
        <div className="profile-picture">
          <div className="profile-picture-background"></div>
        </div>
      </div>
    </div>
  );
}
