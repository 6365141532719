import React from "react";
import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import "./AboutMe.css";
import ResumePdf from "../../assets/Resume/nizam.pdf";
import { NavLink } from "react-router-dom";
import { IoMdArrowDropdown } from "react-icons/io";

export default function AboutMe(props) {
  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;
    Animations.animations.fadeInScreen(props.id);
  };
  // const fadeInSubscription =
  ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  const SCREEN_CONSTSANTS = {
    description:
      "Hi, I'm an experienced IT professional currently serving as AGM & Head of IT at Nec Money Transfer Limited. With over 12 years in IT architecture, network and security systems, and ERP software management, I specialize in enhancing system efficiency and automating processes. My skills include cloud services (Azure, AWS, GCP), system administration, CI/CD tools, and programming languages such as JavaScript, Python, and GoLang.",
    highlights: {
      bullets: [
        "Full Stack Web Developer",
        "Python Developer",
        "DevOps Engineer",
        "Building Rest & Soap API",
        "Managing Databases",
        "Cloud & Data Center",
        "IT Team Management",
        "FinTech",
      ],
      heading: "Here are a Few Highlights:",
    },
  };
  const renderHighlight = () => {
    return SCREEN_CONSTSANTS.highlights.bullets.map((value, i) => (
      <div className="highlight" key={i}>
        <div className="highlight-blob"></div>
        <span>{value}</span>
      </div>
    ));
  };
  return (
    <div className="about-me-container screen-container" id={props.id || ""}>
      <div className="about-me-parent">
        <ScreenHeading title={"About Me"} subHeading={"Why Choose Me?"} />
        <div className="about-me-card">
          <div className="about-me-profile"></div>
          <div className="about-me-details">
            <span className="about-me-description">
              {SCREEN_CONSTSANTS.description}
            </span>
            <div className="about-me-highlights">
              <div className="highlight-heading">
                <span>{SCREEN_CONSTSANTS.highlights.heading}</span>
              </div>
              {renderHighlight()}
            </div>
            <div /* className="about-me-options" */>
              {/* <button
                className="btn primary-btn"
                onClick={() => {
                  let contactMeScreen = document.getElementById("ContactMe");
                  if (!contactMeScreen) return;
                  contactMeScreen.scrollIntoView({ behavior: "smooth" });
                }}
              >
                Contact Me
              </button> */}

              {/* <a href={ResumePdf} download="resume_Mostafa_Nizam.pdf">
                <button className="btn highlighted-btn">Get Resume</button>
              </a> */}

              <button className="btn highlighted-btn dropdown">
                <span>
                  Blog <IoMdArrowDropdown style={{ fontSize: "20px" }} />
                </span>
                <span className="dropdown-content">
                  <span className="d-flex flex-column">
                    <NavLink
                      to="https://www.infotechtu.com/"
                      target="_blank"
                      className="blog-button"
                    >
                      InfoTechTu
                    </NavLink>
                    <NavLink to="/" className="blog-button">
                      Blog 2
                    </NavLink>
                  </span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
