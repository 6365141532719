import React from "react";
import "./Certificate.css";
import { NavLink } from "react-router-dom";

export default function VendorCertificate() {
  return (
    <div className="certificatedetails">
      <div className="container">
        <div className="row py-3">
          <div className="mb-4">
            <NavLink to="/" className="backtohome mt-2">
              Back To Home
            </NavLink>
          </div>

          {/* <h2 style={{borderBottom:'2px solid',width:"100%"}}></h2>     */}

          {/* certificate start */}

          <div className="col-md-12">
            <div class="project-info">
              <h2 style={{ textAlign: "center" }}>Vendor Certificate's</h2>
            </div>

            <div class="work-list">
              <h2>List Of Vendor Certificate:</h2>
              <ul>
                <li>AWS Certified (1x)</li>
                <ul>
                  <li>
                    Certification on “AWS Certified Cloud Practitioner”,
                    organized by Amazon Web Services Training and Certification.
                  </li>
                  <a
                    href="https://drive.google.com/file/d/1GvtmAhYv7BFvRRCZucWHZw8aGRXnUNFf/view?usp=sharing"
                    target="blank"
                  >
                    Certificate Link
                  </a>
                  <br />
                  <a
                    href="https://www.credly.com/badges/16b3bb1d-3bb8-4109-b160-1cf4e67f11bd/public_url"
                    target="blank"
                  >
                    Badge Link
                  </a>
                </ul>
                {/*<li>DevOps</li>
                            <ul>
                            <li>Certification on “DevOps Beginners to Advanced | Decoding DevOps with Projects”, organized by Udemy.com.</li>
                            <li>Certification on “DevOps Coding Boot Camp”, organized by Master Academy.</li>
                            </ul>
                        <li>Programming</li>
                            <ul>
                            <li>Certification on “GoLang Coding Boot Camp”, organized by Master Academy.</li>
                            <li>Certification on “Learning Django”, organized by Lynda.com.</li>
                            <li>Certification on “React.js Essential Training”, organized by Lynda.com.</li>
                            </ul>
                        <li>Windows Server</li>
                            <ul>
                            <li>Certification on “Windows Server 2019 Essential Training”, organized by Lynda.com in 2019.</li>
                            <li>Certification on “Securing Windows Server 2019”, organized by Lynda.com in 2019.</li>
                            </ul>
                        <li>Software Architecture</li>
                            <ul>
                            <li>Certification on “Software Architecture Foundations”, organized by Lynda.com.</li>
                            <li>Certification on “Software Architecture: Domain-Driven Design”, organized by Lynda.com.</li>
                            </ul>
                        <li>Cloud Computing</li>
                            <ul>
                            <li>Earn Batch from Qwiklabs.com: Cloud Engineering, Windows on Google Cloud, Cloud Development, Data Engineering, Google Cloud Essential, Exploring APIs, Baseline-Data, ML, AI</li>
                            </ul>
                        <li>Other</li>
                            <ul>
                            <li>Certification on “Critical Thinking for Better Judgment and Decision-Making”, by Lynda.com.</li>
                            <li>Certification on “Technical Support Fundamentals (Google IT Support)”, organized by Coursera.com.</li>
                            <li>Certification on “Foundations of User Experience (UX) Design”, organized by Coursera.com.</li>
                            </ul>*/}
              </ul>
            </div>
          </div>

          {/* end certificate */}
        </div>
      </div>
    </div>
  );
}
