
import { IoEarthSharp } from "react-icons/io5";

const Project = ({ title, github, description }) => {
    return (
        <div style={{width: "100%"}}>
            <div className="work-list" style={{padding: "20px"}}>
                <h2 style={{display: "flex", alignItems: "center"}}> <IoEarthSharp/> <span style={{paddingLeft: "10px"}}>{title}</span></h2>
                <ul>
                    <li>Github: <a href={`${github}`} target="blank">{github}</a></li>
                    <li>{description}</li>
                </ul>
            </div>
        </div>
    );
};

export default Project;